import { createAction, props } from '@ngrx/store';
import { ISubscription, IUser } from '@metutors/core/models';

// Answer GPT
export const answerGPT = createAction(
  '[GPT] Answer GPT',
  props<{ body: any }>()
);

export const answerGPTSuccess = createAction(
  '[GPT] Answer GPT Success',
  props<{ answer: string; threadId: string; history: any }>()
);

export const answerGPTFailure = createAction(
  '[GPT] Answer GPT Failure',
  props<{ error: any }>()
);

// Record Voice
export const recordVoice = createAction(
  '[GPT] Record Voice',
  props<{ voice: File }>()
);

export const recordVoiceSuccess = createAction(
  '[GPT] Record Voice Success',
  props<{ prompt: string }>()
);

export const recordVoiceFailure = createAction(
  '[GPT] Record Voice Failure',
  props<{ error: any }>()
);

export const gptSubject = createAction(
  '[GPT] GPT Subject',
  props<{ subject: any }>()
);

export const loadRemainingQueries = createAction(
  '[GPT] Load Remaining Queries'
);

export const loadRemainingQueriesSuccess = createAction(
  '[GPT] Load Remaining Queries Success',
  props<{ remainingQueries: number }>()
);

export const loadRemainingQueriesFailure = createAction(
  '[GPT] Load Remaining Queries Failure',
  props<{ error: any }>()
);

export const loadRemainingQueriesEnded = createAction(
  '[GPT] Load Remaining Queries Ended'
);

export const gptDestroyIntroJS = createAction('[GPT] GPT Destroy Intro JS');

export const gptDestroyIntroJSSuccess = createAction(
  '[GPT] GPT Destroy Intro JS Success',
  props<{ user: IUser; token: any }>()
);

export const gptDestroyIntroJSFailure = createAction(
  '[GPT] GPT Destroy Intro JS Failure',
  props<{ error: any }>()
);

export const resetGPTAnswer = createAction('[GPT] Reset GPT Answer');

// Subscribe - Unsubscribe Package
export const subscribeGPTPackage = createAction(
  '[GPT] Subscribe GPT Package',
  props<{ data: any }>()
);

export const subscribeGPTPackageSuccess = createAction(
  '[GPT] Subscribe GPT Package Success',
  props<{ paymentInfo: any }>()
);

export const subscribeGPTPackageFailure = createAction(
  '[GPT] Subscribe GPT Package Failure',
  props<{ error: any }>()
);

export const unsubscribeGPTPackage = createAction(
  '[GPT] Unsubscribe GPT Package'
);

export const unsubscribeGPTPackageSuccess = createAction(
  '[GPT] Unsubscribe GPT Package Success',
  props<{
    token: string;
    message: string;
    subscription: ISubscription;
  }>()
);

export const unsubscribeGPTPackageFailure = createAction(
  '[GPT] Unsubscribe GPT Package Failure',
  props<{ error: any }>()
);

// Upgrade Package
export const upgradeGPTPackage = createAction(
  '[GPT] Upgrade GPT Package',
  props<{ data: any }>()
);

export const upgradeGPTPackageSuccess = createAction(
  '[GPT] Upgrade GPT Package Success',
  props<{ paymentInfo: any }>()
);

export const upgradeGPTPackageFailure = createAction(
  '[GPT] Upgrade GPT Package Failure',
  props<{ error: any }>()
);

// Refill Package
export const refillGPTPackage = createAction(
  '[GPT] Refill GPT Package',
  props<{ data: any }>()
);

export const refillGPTPackageSuccess = createAction(
  '[GPT] Refill GPT Package Success',
  props<{ paymentInfo: any }>()
);

export const refillGPTPackageFailure = createAction(
  '[GPT] Refill GPT Package Failure',
  props<{ error: any }>()
);

// Verify Package Subscription
export const verifyGPTPackageSubscription = createAction(
  '[GPT] Verify GPT Package Subscription',
  props<{ params: any }>()
);

export const verifyGPTPackageSubscriptionSuccess = createAction(
  '[GPT] Verify GPT Package Subscription Success',
  props<{
    token: string;
    paymentInfo: any;
    subscription: ISubscription;
  }>()
);

export const verifyGPTPackageSubscriptionFailure = createAction(
  '[GPT] Verify GPT Package Subscription Failure',
  props<{ error: any }>()
);

// Verify Refill Subscription
export const verifyGPTRefillSubscription = createAction(
  '[GPT] Verify GPT Refill Subscription',
  props<{ id: string }>()
);

export const verifyGPTRefillSubscriptionSuccess = createAction(
  '[GPT] Verify GPT Refill Subscription Success',
  props<{ paymentInfo: any }>()
);

export const verifyGPTRefillSubscriptionFailure = createAction(
  '[GPT] Verify GPT Refill Subscription Failure',
  props<{ error: any }>()
);

// Load History
export const loadGPTHistory = createAction('[GPT] Load GPT History');

export const loadGPTHistorySuccess = createAction(
  '[GPT] Load GPT History Success',
  props<{ list: any[] }>()
);

export const loadGPTHistoryFailure = createAction(
  '[GPT] Load GPT History Failure',
  props<{ error: any }>()
);

export const loadGPTHistoryEnded = createAction('[GPT] Load GPT History Ended');

// Get Free Trial
export const getGPTFreeTrial = createAction(
  '[GPT] Get GPT Free Trial',
  props<{ isRedirect?: boolean }>()
);

export const getGPTFreeTrialSuccess = createAction(
  '[GPT] Get GPT Free Trial Success',
  props<{
    token: string;
    message: string;
    isRedirect?: boolean;
    subscription: ISubscription;
  }>()
);

export const getGPTFreeTrialFailure = createAction(
  '[GPT] Get GPT Free Trial Failure',
  props<{ error: any }>()
);

export const getGPTFreeTrialEnded = createAction(
  '[GPT] Get GPT Free Trial Ended'
);

// Load User Subscription
export const loadUserGPTSubscription = createAction(
  '[GPT] Load User GPT Subscription'
);

export const loadUserGPTSubscriptionSuccess = createAction(
  '[GPT] Load User GPT Subscription Success',
  props<{ subscription: ISubscription }>()
);

export const loadUserGPTSubscriptionFailure = createAction(
  '[GPT] Load User GPT Subscription Failure',
  props<{ error: any }>()
);

// Load All Subscriptions
export const loadSubscriptions = createAction(
  '[GPT] Load GPT Subscriptions',
  props<{ params: { page: number; search: string; per_page: number } }>()
);

export const loadSubscriptionsSuccess = createAction(
  '[GPT] Load GPT Subscriptions Success',
  props<{ subscriptions: any[]; subscriptionsCounts: any }>()
);

export const loadSubscriptionsFailure = createAction(
  '[GPT] Load GPT Subscriptions Failure',
  props<{ error: any }>()
);

// Give GPT Feedback
export const giveGPTFeedback = createAction(
  '[GPT] Give GPT Feedback',
  props<{ body: any }>()
);

export const giveGPTFeedbackSuccess = createAction(
  '[GPT] Give GPT Feedback Success',
  props<{ message: string }>()
);

export const giveGPTFeedbackFailure = createAction(
  '[GPT] Give GPT Feedback Failure',
  props<{ error: any }>()
);

// Load GPT Feedback
export const loadGPTFeedback = createAction(
  '[GPT] Load GPT Feedback',
  props<{ params: { page: number; search: string; per_page: number } }>()
);

export const loadGPTFeedbackSuccess = createAction(
  '[GPT] Load GPT Feedback Success',
  props<{ feedbacks: any[]; feedbacksCounts: any }>()
);

export const loadGPTFeedbackFailure = createAction(
  '[GPT] Load GPT Feedback Failure',
  props<{ error: any }>()
);

// Load GPT Feedback By Id
export const loadGPTFeedbackById = createAction(
  '[GPT] Load GPT Feedback By Id',
  props<{ id: number }>()
);

export const loadGPTFeedbackByIdSuccess = createAction(
  '[GPT] Load GPT Feedback By Id Success',
  props<{ feedback: any }>()
);

export const loadGPTFeedbackByIdFailure = createAction(
  '[GPT] Load GPT Feedback By Id Failure',
  props<{ error: any }>()
);

// Swipe GPT History Right
export const swipeGPTHistoryRight = createAction(
  '[GPT] Swipe GPT History Right'
);

// Close Subscription Popup
export const closeGptSubscriptionPopup = createAction(
  '[GPT] Close GPT Subscription Popup'
);
