export const generalConstants = {
  nationalId: 3,
  trialSubscriptionDays: 30,
  defaultAvatarPath: 'assets/defaults/avatar.png',
  defaultFemalePath: 'assets/defaults/female.png',
  regex: {
    password: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$',
    url:
      '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$',
  },
};
