/* eslint-disable import/no-deprecated */
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import camelcaseKeys from 'camelcase-keys';
import * as fromCore from '@metutors/core/state';
import { GPTService } from '@metutors/core/services';
import * as gptActions from '../actions/gpt.actions';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as userActions from '../actions/user.actions';
import * as generalActions from '../actions/general.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AlertNotificationService } from '@metutors/core/components';
import {
  map,
  mergeMap,
  switchMap,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';

@Injectable()
export class GPTEffects {
  answerGPT$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.answerGPT),
      withLatestFrom(this._store.select(fromCore.selectGPTThreadId)),
      mergeMap(([action, threadId]) =>
        this._gptService.answerGPT({ ...action.body, threadId }).pipe(
          map(({ answer, threadId, history }) =>
            gptActions.answerGPTSuccess({
              answer,
              history,
              threadId,
            })
          ),
          catchError(error =>
            of(
              gptActions.answerGPTFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  recordVoice$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.recordVoice),
      mergeMap(action =>
        this._gptService.recordVoice(action.voice).pipe(
          map(({ prompt }) =>
            gptActions.recordVoiceSuccess({
              prompt,
            })
          ),
          catchError(error =>
            of(
              gptActions.recordVoiceFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  loadRemainingGptQueries$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.gptSubject),
      withLatestFrom(this._store.select(fromCore.selectUser)),
      mergeMap(([{ subject }, user]) => {
        if (user) {
          return this._gptService.loadRemainingQueries(subject).pipe(
            map(remainingQueries =>
              gptActions.loadRemainingQueriesSuccess({
                remainingQueries,
              })
            ),
            catchError(error =>
              of(
                gptActions.loadRemainingQueriesFailure({
                  error: error?.error?.message || error?.error?.errors,
                })
              )
            )
          );
        } else {
          return of(gptActions.loadRemainingQueriesEnded());
        }
      })
    )
  );

  loadRemainingQueries$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.loadRemainingQueries),
      withLatestFrom(this._store.select(fromCore.selectGPTSubject)),
      mergeMap(([, _subject]) => {
        if (_subject) {
          return this._gptService.loadRemainingQueries(_subject).pipe(
            map(remainingQueries =>
              gptActions.loadRemainingQueriesSuccess({
                remainingQueries,
              })
            ),
            catchError(error =>
              of(
                gptActions.loadRemainingQueriesFailure({
                  error: error?.error?.message || error?.error?.errors,
                })
              )
            )
          );
        } else {
          return of(gptActions.loadRemainingQueriesEnded());
        }
      })
    )
  );

  gptSubject$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        ...[
          userActions.signInSuccess,
          gptActions.getGPTFreeTrialSuccess,
          gptActions.verifyGPTRefillSubscriptionSuccess,
          gptActions.verifyGPTPackageSubscriptionSuccess,
        ]
      ),
      withLatestFrom(this._store.select(fromCore.selectGPTSubject)),
      switchMap(([_, subject]) => [fromCore.gptSubject({ subject })])
    )
  );

  subscribeGPTPackage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.subscribeGPTPackage),
      mergeMap(({ data }) =>
        this._gptService.subscribePackage(data).pipe(
          map(paymentInfo =>
            gptActions.subscribeGPTPackageSuccess({
              paymentInfo,
            })
          ),
          catchError(error =>
            of(
              gptActions.subscribeGPTPackageFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  unsubscribeGPTPackage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.unsubscribeGPTPackage),
      mergeMap(() =>
        this._gptService.unsubscribePackage().pipe(
          map(({ message, token, subscription }) =>
            gptActions.unsubscribeGPTPackageSuccess({
              token,
              message,
              subscription,
            })
          ),
          catchError(error =>
            of(
              gptActions.unsubscribeGPTPackageFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  upgradeGPTPackage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.upgradeGPTPackage),
      mergeMap(({ data }) =>
        this._gptService.upgradePackage(data).pipe(
          map(paymentInfo =>
            gptActions.upgradeGPTPackageSuccess({
              paymentInfo,
            })
          ),
          catchError(error =>
            of(
              gptActions.upgradeGPTPackageFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  refillGPTPackage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.refillGPTPackage),
      mergeMap(({ data }) =>
        this._gptService.refillPackage(data).pipe(
          map(paymentInfo =>
            gptActions.refillGPTPackageSuccess({
              paymentInfo,
            })
          ),
          catchError(error =>
            of(
              gptActions.refillGPTPackageFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  verifyGPTPackageSubscription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.verifyGPTPackageSubscription),
      mergeMap(({ params }) =>
        this._gptService.verifyPackageSubscription(params).pipe(
          map(({ paymentInfo, token }) => {
            const jwtHelper = new JwtHelperService();
            const decodeToken = camelcaseKeys(jwtHelper.decodeToken(token), {
              deep: true,
            });
            const subscription: any = decodeToken?.subscription;

            return gptActions.verifyGPTPackageSubscriptionSuccess({
              token,
              paymentInfo,
              subscription,
            });
          }),
          catchError(error =>
            of(
              gptActions.verifyGPTPackageSubscriptionFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  verifyGPTRefillSubscription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.verifyGPTRefillSubscription),
      mergeMap(({ id }) =>
        this._gptService.verifyRefillSubscription(id).pipe(
          map(({ paymentInfo }) =>
            gptActions.verifyGPTRefillSubscriptionSuccess({
              paymentInfo,
            })
          ),
          catchError(error =>
            of(
              gptActions.verifyGPTRefillSubscriptionFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  loadGPTHistory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.loadGPTHistory),
      withLatestFrom(this._store.select(fromCore.selectGPTHistory)),
      mergeMap(([_, _history]) => {
        if (!_history || !_history?.length) {
          return this._gptService.loadGPTHistory().pipe(
            map(list =>
              gptActions.loadGPTHistorySuccess({
                list,
              })
            ),
            catchError(error =>
              of(
                gptActions.loadGPTHistoryFailure({
                  error: error?.error?.message || error?.error?.errors,
                })
              )
            )
          );
        } else {
          return of(gptActions.loadGPTHistoryEnded());
        }
      })
    )
  );

  getGPTFreeTrial$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.getGPTFreeTrial),
      withLatestFrom(this._store.select(fromCore.selectUser)),
      mergeMap(([action, user]) => {
        if (user) {
          return this._gptService.getGPTFreeTrial().pipe(
            map(({ message, token }) => {
              const jwtHelper = new JwtHelperService();
              const decodeToken: any = camelcaseKeys(
                jwtHelper.decodeToken(token),
                {
                  deep: true,
                }
              );
              const subscription: any = decodeToken?.subscription;

              return gptActions.getGPTFreeTrialSuccess({
                token,
                message,
                subscription,
                isRedirect: action.isRedirect,
              });
            }),
            catchError(error =>
              of(
                gptActions.getGPTFreeTrialFailure({
                  error: error?.error?.message || error?.error?.errors,
                })
              )
            )
          );
        } else {
          return of(gptActions.getGPTFreeTrialEnded());
        }
      })
    )
  );

  loadUserGPTSubscription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.loadUserGPTSubscription),
      mergeMap(_ =>
        this._gptService.loadUserGPTSubscription().pipe(
          map(subscription => {
            return gptActions.loadUserGPTSubscriptionSuccess({
              subscription,
            });
          }),
          catchError(error =>
            of(
              gptActions.loadUserGPTSubscriptionFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  loadSubscriptions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.loadSubscriptions),
      mergeMap(action =>
        this._gptService.getSubscriptions(action.params).pipe(
          map(response =>
            gptActions.loadSubscriptionsSuccess({
              subscriptions: response.subscriptions,
              subscriptionsCounts: response.subscriptionsCounts,
            })
          ),
          catchError(error =>
            of(
              gptActions.loadSubscriptionsFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  giveGPTFeedback$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.giveGPTFeedback),
      mergeMap(action =>
        this._gptService.giveFeedback(action.body).pipe(
          map(({ message }) =>
            gptActions.giveGPTFeedbackSuccess({
              message,
            })
          ),
          catchError(error =>
            of(
              gptActions.giveGPTFeedbackFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  loadGPTFeedback$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.loadGPTFeedback),
      mergeMap(action =>
        this._gptService.getFeedbacks(action.params).pipe(
          map(response =>
            gptActions.loadGPTFeedbackSuccess({
              feedbacks: response.feedbacks,
              feedbacksCounts: response.feedbacksCounts,
            })
          ),
          catchError(error =>
            of(
              gptActions.loadGPTFeedbackFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  loadGPTFeedbackById$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.loadGPTFeedbackById),
      mergeMap(action =>
        this._gptService.getFeedbackById(action.id).pipe(
          map(feedback =>
            gptActions.loadGPTFeedbackByIdSuccess({
              feedback,
            })
          ),
          catchError(error =>
            of(
              gptActions.loadGPTFeedbackByIdFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  closeGptSubscriptionPopup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.closeGptSubscriptionPopup),
      withLatestFrom(this._store.select(fromCore.selectSubscription)),
      mergeMap(([_, _subscription]) => {
        if (_subscription && +_subscription?.isPopup! === 1) {
          return this._gptService.closeSubscriptionPopup().pipe(
            map(() =>
              gptActions.loadUserGPTSubscriptionSuccess({
                subscription: { ..._subscription, isPopup: 0 },
              })
            ),
            catchError(() => of(fromCore.doNothing()))
          );
        } else {
          return of(generalActions.doNothing());
        }
      })
    )
  );

  gptDestroyIntroJS$ = createEffect(() =>
    this._actions$.pipe(
      ofType(gptActions.gptDestroyIntroJS),
      mergeMap(() =>
        this._gptService.gptDestroyIntroJS().pipe(
          map(response => {
            const jwtHelper = new JwtHelperService();
            const decodeToken = camelcaseKeys(
              jwtHelper.decodeToken(response.token),
              {
                deep: true,
              }
            );
            const user: any = decodeToken?.user;

            return gptActions.gptDestroyIntroJSSuccess({
              user,
              token: response.token,
            });
          }),
          catchError(error =>
            of(
              gptActions.gptDestroyIntroJSFailure({
                error: error?.error?.message || error?.error?.errors,
              })
            )
          )
        )
      )
    )
  );

  getGPTFreeTrialSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(gptActions.getGPTFreeTrialSuccess),
        map(action => {
          if (action.isRedirect) {
            this._router.navigate(['/full']);
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  successMessages$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          ...[
            gptActions.getGPTFreeTrialSuccess,
            gptActions.giveGPTFeedbackSuccess,
          ]
        ),
        map(({ message }) => this._alertNotificationService.success(message))
      ),
    {
      dispatch: false,
    }
  );

  errorMessages$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          ...[
            gptActions.answerGPTFailure,
            gptActions.giveGPTFeedbackFailure,
            gptActions.getGPTFreeTrialFailure,
            gptActions.refillGPTPackageFailure,
            gptActions.upgradeGPTPackageFailure,
            gptActions.subscribeGPTPackageFailure,
            gptActions.unsubscribeGPTPackageFailure,
            gptActions.verifyGPTRefillSubscriptionFailure,
            gptActions.verifyGPTPackageSubscriptionFailure,
          ]
        ),
        map(({ error }) => this._alertNotificationService.error(error))
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private _router: Router,
    private _store: Store<any>,
    private _actions$: Actions,
    private _gptService: GPTService,
    private _alertNotificationService: AlertNotificationService
  ) {}
}
