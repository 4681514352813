import * as _moment from 'moment';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import camelcaseKeys from 'camelcase-keys';
import { isNil, omitBy, sortBy } from 'lodash';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  GPTSubjects,
  GPTFriendlyAI,
  GPTSchoolStores,
  GPTUniversityStores,
} from '@metutors/config';

@Injectable({
  providedIn: 'root',
})
export class GPTService {
  apiUrl = environment.API_URL;

  constructor(private _http: HttpClient) {}

  answerGPT(body: any): Observable<any> {
    return this._http
      .post<any>(
        `${this.apiUrl}chat`,
        omitBy(
          {
            name: body.name,
            prompt: body.prompt,
            subject_id: body.subject,
            thread_id: body.threadId || undefined,
            file_ids:
              body.fileIDs && body.fileIDs?.length ? body.fileIDs : undefined,
          },
          isNil
        )
      )
      .pipe(
        map(response => ({
          threadId: response?.data?.thread_id,
          answer: response?.data?.message?.text?.value,
          history: {
            question: body.prompt,
            subjectId: body.subject,
            id: response?.data?.message_id,
            answer: response?.data?.message?.text?.value,
          },
        }))
      )
      .pipe(catchError(this.errorHandler));
  }

  recordVoice(file: File): Observable<any> {
    const formData = new FormData();

    formData.append('file', file);

    return this._http
      .post<any>(`${this.apiUrl}voice-to-text`, formData)
      .pipe(
        map(response => ({
          prompt: response?.response?.text,
        }))
      )
      .pipe(catchError(this.errorHandler));
  }

  loadRemainingQueries(subject: any): Observable<any> {
    return this._http
      .get<{ queries: number }>(
        `${this.apiUrl}subscription/gpt/${subject.id}?subject_name=${subject.nameEn}`
      )
      .pipe(map(response => response.queries));
  }

  subscribePackage(body: any): Observable<any> {
    return this._http.post(`${this.apiUrl}subscription`, body);
  }

  unsubscribePackage(): Observable<any> {
    return this._http
      .post<{ data: any; message: string }>(
        `${this.apiUrl}subscription/cancel`,
        {}
      )
      .pipe(
        map(response => ({
          message: response?.message,
          token: response?.data?.token,
          subscription: {
            ...camelcaseKeys(response?.data, {
              deep: true,
            }),
            isInstructions: response?.data?.user?.is_instructions,
          },
        }))
      )
      .pipe(catchError(this.errorHandler));
  }

  upgradePackage(body: any): Observable<any> {
    return this._http.post(`${this.apiUrl}subscription/upgrade`, body);
  }

  refillPackage(body: any): Observable<any> {
    return this._http.post(`${this.apiUrl}purchase/queries`, body);
  }

  verifyPackageSubscription(params: any): Observable<any> {
    return this._http
      .get<{ payment_details: any; token: string }>(
        `${this.apiUrl}subscription/status`,
        {
          params,
        }
      )
      .pipe(
        map(response => ({
          token: response?.token,
          paymentInfo: camelcaseKeys(response?.payment_details, {
            deep: true,
          }),
        }))
      )
      .pipe(catchError(this.errorHandler));
  }

  verifyRefillSubscription(id: string): Observable<any> {
    return this._http
      .post<{ payment_details: any; token: string }>(
        `${this.apiUrl}purchase/queries/status`,
        { id }
      )
      .pipe(
        map(response => ({
          paymentInfo: camelcaseKeys(response?.payment_details, {
            deep: true,
          }),
        }))
      )
      .pipe(catchError(this.errorHandler));
  }

  loadGPTHistory(): Observable<any> {
    return this._http
      .get<{ data: any }>(`${this.apiUrl}subscription/history`)
      .pipe(
        map(response => {
          const output: any[] = [];
          const data =
            camelcaseKeys(response?.data, {
              deep: true,
            }) || [];

          data?.forEach((item: any) => {
            const existing = output.filter((v, i) => +v.id == +item.subjectId);

            if (existing.length) {
              const existingIndex = output.indexOf(existing[0]);

              output[existingIndex].questions = [
                ...output[existingIndex].questions,
                {
                  ...item,
                  answer: item.answer,
                },
              ];
            } else {
              output.push({
                ...[
                  ...GPTSubjects,
                  GPTFriendlyAI,
                  ...GPTSchoolStores,
                  ...GPTUniversityStores,
                ].find(subject => +subject.id === +item.subjectId),
                questions: [
                  {
                    ...item,
                    answer: item.answer,
                  },
                ],
              });
            }
          });

          return sortBy(output, 'id');
        })
      )
      .pipe(catchError(this.errorHandler));
  }

  getGPTFreeTrial(): Observable<any> {
    return this._http
      .post<{ message: string; data: any }>(
        `${this.apiUrl}subscription/trial`,
        {}
      )
      .pipe(
        map(response => ({
          message: response?.message,
          token: response?.data?.token,
        }))
      )
      .pipe(catchError(this.errorHandler));
  }

  loadUserGPTSubscription(): Observable<any> {
    return this._http
      .get<{ data: any }>(`${this.apiUrl}user/subscription`)
      .pipe(
        map(response =>
          camelcaseKeys(response.data, {
            deep: true,
          })
        )
      )
      .pipe(catchError(this.errorHandler));
  }

  getSubscriptions(params: any): Observable<any> {
    return this._http
      .get<{
        revenue: number;
        basic_pack: number;
        subscriptions: any;
        total_users: number;
        premium_pack: number;
        total_refill: number;
        essential_pack: number;
        total_students: number;
        total_teachers: number;
        recurring_students: number;
      }>(`${this.apiUrl}admin/subscriptions`, { params })
      .pipe(
        map(response => ({
          subscriptionsCounts: {
            revenue: response?.revenue,
            basicPack: response?.basic_pack,
            totalUsers: response?.total_users,
            premiumPack: response?.premium_pack,
            totalRefill: response?.total_refill,
            total: response?.subscriptions?.total,
            totalStudents: response?.total_students,
            totalTeachers: response?.total_teachers,
            essentialPack: response?.essential_pack,
            recurringStudents: response?.recurring_students,
          },
          subscriptions: camelcaseKeys(response?.subscriptions?.data, {
            deep: true,
          }),
        }))
      )
      .pipe(catchError(this.errorHandler));
  }

  giveFeedback(body: any): Observable<any> {
    return this._http
      .post<{ data: any; message: string }>(
        `${this.apiUrl}subscription/feedback`,
        body
      )
      .pipe(
        map(response => ({
          message: response?.message,
        }))
      )
      .pipe(catchError(this.errorHandler));
  }

  getFeedbacks(params: any): Observable<any> {
    return this._http
      .get<{ reviews: any }>(`${this.apiUrl}subscription/feedbacks`, { params })
      .pipe(
        map(response => ({
          feedbacksCounts: response?.reviews?.total,
          feedbacks: camelcaseKeys(response?.reviews?.data, {
            deep: true,
          }),
        }))
      )
      .pipe(catchError(this.errorHandler));
  }

  getFeedbackById(id: number): Observable<any> {
    return this._http
      .get<{ feedback: any }>(`${this.apiUrl}subscription/feedback/${id}`)
      .pipe(
        map(response =>
          camelcaseKeys(response?.feedback, {
            deep: true,
          })
        )
      )
      .pipe(catchError(this.errorHandler));
  }

  closeSubscriptionPopup(): Observable<any> {
    return this._http
      .post(`${this.apiUrl}subscription-popup`, {})
      .pipe(catchError(this.errorHandler));
  }

  gptDestroyIntroJS(): Observable<any> {
    return this._http
      .post(`${this.apiUrl}user/instructions`, {})
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
