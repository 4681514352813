export const environment = {
  production: true,
  API_URL: 'https://backend.metutors.com/api/',
  clientUrl: 'https://metutors.com',
  clientAiUrl: 'https://metutors.ai',
  imageURL: 'https://backend.metutors.com/uploads/images/',
  videoURL: 'https://backend.metutors.com/uploads/teacher_videos/',
  teacherDocs: 'https://backend.metutors.com/uploads/teacher_documents/',
  programImage: 'https://backend.metutors.com/storage/uploads/program_images/',
  blogsURL: 'http://backend.metutors.com/storage/uploads/blogs/',
  countriesURL: 'http://backend.metutors.com/storage/uploads/countries/',
  fieldOfStudiesImage: 'https://backend.metutors.com/storage/uploads/field_of_studies/',
  uploadsPath: 'https://backend.metutors.com/uploads/',
  cookieKey: '61962f35cd3061ba61697935a8b9b34bcb6f03d3',
  chatbotURL: 'https://stgchatbot.metutors.com/',
  contactImage: 'https://backend.metutors.com/assets/images/contact_images/',
  uniprepDocs: 'https://backend.metutors.com/storage/uploads/uniprep/',
  openAIKey: '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',
  maintenanceMode: false,
cometchat: {
    region: 'eu',
    appId: '232318be14a45675',
    authKey: '3487cfb259f00657390c3bef3b4aa896d039b085'
  },
   paypal: {
    authend: {},
    returnurl: {
      tutor: 'https://metutors.com/tutor/settings',
      student: 'https://metutors.com/student/settings',
    },
    appId:
      'AdL4hL9B9yFHWc0-Ga0feyKAp3QdHxlopkMY_E1tWxifqHTcz0PnUDAjd43ssygoezVjrla3wTfwRuK4',
  },
  pusher: {
    key: 'edfd6675ba1bf9939d9a',
    cluster: 'ap2',
  },
};
