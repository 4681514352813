<div class="position-fixed chatbot d-flex flex-column" [class.is-open]="(openChatbot$ | async)!">
  <div class="heading d-flex justify-content-between align-items-center px-3">
    <div class="d-flex align-items-center">
      <div class="logo me-2">
        <img src="assets/logo/logo-icon.png" alt="" draggable="false" width="100%" height="100%" />
      </div>
      <span class="f-size-18-md-sm">{{ 'DANIA' | translate }}</span>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
  
  <div class="pt-2 px-3 live-content">
    <button class="live-chat w-100" type="button">
      <svg-icon name="live-chat" [applyClass]="true"></svg-icon>
      <span class="ms-2">{{ 'LIVE_CHAT' | translate }}</span>
    </button>
  </div>

  <div class="content px-3 flex-grow-1" ngx-auto-scroll [lock-y-offset]="10" observe-attributes *ngLet="(steps$ | async)! as steps">
    <div class="chat-area mt-3">
      <ng-container *ngFor="let step of steps; let i = index">
        <div class="chat-item d-flex mb-3" [@dropMenuAnimate] *ngIf="step?.number === 1">
          <div class="avatar mx-2 d-flex align-items-center rounded-circle">
            <img src="assets/logo/logo-icon.png" draggable="false" alt="" width="100%" height="100%" />
          </div>
          <div>
            <div class="message">
              <bdi class="d-inline-block">{{ 'HELLO_THERE_WHAT_NAME' | translate }}</bdi>
            </div>
            <div class="info mt-1 d-flex">
              <strong>{{ 'DANIA' | translate }}</strong>
              <span class="mx-1">{{ step?.date | amTimeAgo }}</span>
            </div>
          </div>
        </div>

        <div class="chat-item d-flex mb-3 flex-row-reverse my-chat" [@dropMenuAnimate] *ngIf="step?.number === 2">
          <div class="avatar mx-2 d-flex align-items-center rounded-circle">
            <img [src]="loggedUser?.avatar!" meTutorsDefaultAvatar draggable="false" alt="" width="100%" height="100%" class="rounded-circle" />
          </div>
          <div>
            <div class="message">
              <bdi class="d-inline-block">{{ step?.name }}</bdi>
            </div>
            <div class="info mt-1 d-flex flex-row-reverse">
              <strong>{{ 'ME' | translate }}</strong>
              <span class="mx-1">{{ step?.date | amTimeAgo }}</span>
            </div>
          </div>
        </div>

        <div class="chat-item d-flex mb-3" [@dropMenuAnimate] *ngIf="step?.number === 2">
          <div class="avatar mx-2 d-flex align-items-center rounded-circle">
            <img src="assets/logo/logo-icon.png" draggable="false" alt="" width="100%" height="100%" />
          </div>
          <div>
            <div class="message">
              <bdi class="d-inline-block">{{ 'GIVE_US_EMAIL_TOUCH_WITH' | translate }}</bdi>
              <!-- <input type="email" placeholder="{{ 'TYPE_YOUR_EMAIL' | translate }}" class="mt-1 email-message"
                     (keyup.enter)="onWriteEmail($event, steps)"> -->
            </div>
            <div class="info mt-1 d-flex">
              <strong>{{ 'DANIA' | translate }}</strong>
              <span class="mx-1">{{ step?.date | amTimeAgo }}</span>
            </div>
          </div>
        </div>

        <div class="chat-item d-flex mb-3 flex-row-reverse my-chat" [@dropMenuAnimate] *ngIf="step?.number === 3">
          <div class="avatar mx-2 d-flex align-items-center rounded-circle">
            <img [src]="loggedUser?.avatar!" meTutorsDefaultAvatar draggable="false" alt="" width="100%" height="100%" class="rounded-circle" />
          </div>
          <div>
            <div class="message">
              <bdi class="d-inline-block">{{ step?.name }}</bdi>
            </div>
            <div class="info mt-1 d-flex flex-row-reverse">
              <strong>{{ 'ME' | translate }}</strong>
              <span class="mx-1">{{ step?.date | amTimeAgo }}</span>
            </div>
          </div>
        </div>

        <div class="chat-item d-flex mb-3" [@dropMenuAnimate] *ngIf="step?.number === 3">
          <div class="avatar mx-2 d-flex align-items-center rounded-circle">
            <img src="assets/logo/logo-icon.png" draggable="false" alt="" width="100%" height="100%" />
          </div>
          <div>
            <div class="message">
              <bdi class="d-inline-block">{{ 'ARE_YOU_A' | translate }}</bdi>
              <mat-radio-group aria-label="Select an option" class="d-block" (change)="changeChatbotNumber($event.value, 4, false)">
                <mat-radio-button value="PARENT_SM" class="me-2 text-capitalize">{{ 'PARENT_SM' | translate }}</mat-radio-button>
                <mat-radio-button value="STUDENT_SM" class="me-2 text-capitalize">{{ 'STUDENT_SM' | translate }}</mat-radio-button>
                <mat-radio-button value="TUTOR_SM" class="text-capitalize">{{ 'TUTOR_SM' | translate }}</mat-radio-button>
              </mat-radio-group>
              <div class="for-hurry mt-2">
                <span>{{ 'FOR_MORE_HURRY_WHATSAPP_NOW' | translate }}</span>
                <div class="d-flex align-items-center mt-1">
                  <img src="assets/svg/whatsapp.svg" alt="" width="20px" height="20px" class="me-2" />
                  <a href="https://wa.me/+962798275863" target="_blank"><bdi>+962 7 9827 5863</bdi></a>
                </div>
              </div>
            </div>
            <div class="info mt-1 d-flex">
              <strong>{{ 'DANIA' | translate }}</strong>
              <span class="mx-1">{{ step?.date | amTimeAgo }}</span>
            </div>
          </div>
        </div>

        <div class="chat-item d-flex mb-3 flex-row-reverse my-chat" [@dropMenuAnimate] *ngIf="step?.number === 4 && !loggedUser">
          <div class="avatar mx-2 d-flex align-items-center rounded-circle">
            <img src="" meTutorsDefaultAvatar draggable="false" alt="" width="100%" height="100%" class="rounded-circle" />
          </div>
          <div>
            <div class="message">
              <bdi class="d-inline-block text-capitalize">{{ step?.name | translate }}</bdi>
            </div>
            <div class="info mt-1 d-flex flex-row-reverse">
              <strong>{{ 'ME' | translate }}</strong>
              <span class="mx-1">{{ step?.date | amTimeAgo }}</span>
            </div>
          </div>
        </div>

        <div class="chat-item d-flex mb-3" [@dropMenuAnimate] *ngIf="step?.number === 4 && (step?.name === 'PARENT_SM' || step?.name === 'STUDENT_SM')">
          <div class="avatar mx-2 d-flex align-items-center rounded-circle">
            <img src="assets/logo/logo-icon.png" draggable="false" alt="" width="100%" height="100%" />
          </div>
          <div>
            <div class="message">
              <bdi class="d-block">{{ 'HI' | translate }} {{ loggedUser?.firstName || steps[1]?.name }}</bdi>
              <bdi class="d-inline-block gray-text">{{ 'SELECT_SERVICE_YOU_WANT' | translate }}</bdi>
              <strong class="d-block mt-2 mb-1 strong-tut">{{ 'PERSONALIZED_TUTORING' | translate }}</strong>
              <div class="list-options">
                <button mat-button class="me-1" (click)="changeChatbotNumber(HOMEWORK_HELP, 5, false)"
                        *ngLet="('HOMEWORK_HELP' | translate) as HOMEWORK_HELP">
                  {{ 'HOMEWORK_HELP' | translate }}
                </button>
                <button mat-button class="me-1" (click)="changeChatbotNumber(ONE_LIVE_TUTORING, 5, false)"
                        *ngLet="('ONE_LIVE_TUTORING' | translate) as ONE_LIVE_TUTORING">
                  {{ 'ONE_LIVE_TUTORING' | translate }}
                </button>
                <button mat-button class="me-1" (click)="changeChatbotNumber(PROJECT_SUPPORT, 5, false)"
                        *ngLet="('PROJECT_SUPPORT' | translate) as PROJECT_SUPPORT">
                  {{ 'PROJECT_SUPPORT' | translate }}
                </button>
                <button mat-button class="me-1" (click)="changeChatbotNumber(PREP_COLLEGE_ADMISSION_TESTS, 5, false)"
                        *ngLet="('PREP_COLLEGE_ADMISSION_TESTS' | translate) as PREP_COLLEGE_ADMISSION_TESTS">
                  {{ 'PREP_COLLEGE_ADMISSION_TESTS' | translate }}
                </button>
              </div>
              <strong class="d-block mt-2 mb-1 strong-tut">{{ 'INSTANT_QUESTION_SOLVER' | translate }}</strong>
              <div class="list-options">
                <button mat-button class="me-1" (click)="changeChatbotNumber(SCHOOL_GPTS, 5, false)"
                        *ngLet="('SCHOOL_GPTS' | translate) as SCHOOL_GPTS">
                  {{ 'SCHOOL_GPTS' | translate }}
                </button>
                <button mat-button class="me-1" (click)="changeChatbotNumber(UNIVERSITY_GPTS, 5, false)"
                        *ngLet="('UNIVERSITY_GPTS' | translate) as UNIVERSITY_GPTS">
                  {{ 'UNIVERSITY_GPTS' | translate }}
                </button>
              </div>
              <bdi class="d-inline-block gray-text mt-2">{{ 'EXPLORE_OUR_TUTORS' | translate }}</bdi>
              <div class="list-options" *ngLet="(programs$ | async)! as programs">
                <span class="gray-text me-2">{{ 'COVERING' | translate }}</span>
                <button mat-button class="me-1" (click)="changeChatbotNumber(program?.name!, 5, false)" *ngFor="let program of programs">
                  {{ program?.code }}
                </button>
                <!-- <button mat-button class="me-1" (click)="changeChatbotNumber('IB', 5, false)">IB</button>
                <button mat-button class="me-1" (click)="changeChatbotNumber('IGCSE', 5, false)">IGCSE</button>
                <button mat-button class="me-1" (click)="changeChatbotNumber('National Curriculum', 5, false)">National Curriculum</button> -->
              </div>
              <bdi class="d-inline-block gray-text mt-2">{{ 'DO_NEED_ASSISTANCES_COURSE_BOOKING' | translate }}</bdi>
              <div class="list-options">
                <button mat-button class="me-1" *ngLet="('NEED_ASSISTANCES_COURSE_BOOKING' | translate) as NEED_ASSISTANCES_COURSE_BOOKING"
                        (click)="changeChatbotNumber(NEED_ASSISTANCES_COURSE_BOOKING, 5, false)">
                  {{ 'YES' | translate }}
                </button>
              </div>
              <bdi class="d-block text-center gray-text">{{ 'OR' | translate }}</bdi>
              <div class="list-options" *ngLet="('WANT_GET_TOUCH_OUR_TUTORS' | translate) as WANT_GET_TOUCH_OUR_TUTORS">
                <button mat-button class="me-1 gray-text" (click)="changeChatbotNumber(WANT_GET_TOUCH_OUR_TUTORS, 5, false)">
                  {{ 'WANT_GET_TOUCH_OUR_TUTORS' | translate }}
                </button>
              </div>
              <bdi class="d-block text-center gray-text">{{ 'OR' | translate }}</bdi>
              <bdi class="d-block gray-text">
                {{ 'TYPE_WHAT_NEED_HELP_WITH' | translate }}
                <img src="assets/png/emoji.png" draggable="false" width="17px" height="17px" alt="" class="ms-1" />
              </bdi>
            </div>
            <div class="info mt-1 d-flex">
              <strong>{{ 'DANIA' | translate }}</strong>
              <span class="mx-1">{{ step?.date | amTimeAgo }}</span>
            </div>
          </div>
        </div>

        <div class="chat-item d-flex mb-3" [@dropMenuAnimate] *ngIf="step?.number === 4 && step?.name === 'TUTOR_SM'">
          <div class="avatar mx-2 d-flex align-items-center rounded-circle">
            <img src="assets/logo/logo-icon.png" draggable="false" alt="" width="100%" height="100%" />
          </div>
          <div>
            <div class="message">
              <bdi class="d-inline-block">
                {{ 'HELLO' | translate }} {{ loggedUser?.firstName || steps[1]?.name }}, {{ 'HOW_MAY_ASSIST_YOU_TODAY' | translate }}
              </bdi>
              <div class="for-hurry mt-2">
                <span>{{ 'FOR_MORE_HURRY_WHATSAPP_NOW' | translate }}</span>
                <div class="d-flex align-items-center mt-1">
                  <img src="assets/svg/whatsapp.svg" alt="" width="20px" height="20px" class="me-2" />
                  <a href="https://wa.me/+962798275863" target="_blank"><bdi>+962 7 9827 5863</bdi></a>
                </div>
              </div>
            </div>
            <div class="info mt-1 d-flex">
              <strong>{{ 'DANIA' | translate }}</strong>
              <span class="mx-1">{{ step?.date | amTimeAgo }}</span>
            </div>
          </div>
        </div>

        <div class="chat-item d-flex mb-3" [ngClass]="{'flex-row-reverse my-chat': !step?.isMetutors}" [@dropMenuAnimate] *ngIf="step?.number === 5">
          <div class="avatar mx-2 d-flex align-items-center rounded-circle">
            <img [src]="'assets/logo/logo-icon.png'" *ngIf="step?.isMetutors" meTutorsDefaultAvatar draggable="false" alt="" width="100%" height="100%" />
            <img [src]="loggedUser?.avatar!" *ngIf="!step?.isMetutors" meTutorsDefaultAvatar draggable="false" alt="" width="100%" height="100%" class="rounded-circle" />
          </div>
          <div>
            <div class="message">
              <bdi class="d-inline-block">{{ step?.name }}</bdi>
            </div>
            <div class="info mt-1 d-flex" [class.flex-row-reverse]="!step?.isMetutors">
              <strong>{{ (step?.isMetutors ? 'DANIA' : 'ME') | translate }}</strong>
              <span class="mx-1">{{ step?.date | amTimeAgo }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="ask-anything px-3 pt-3 pb-1" *ngLet="(steps$ | async)! as steps">
    <div class="stage d-flex align-items-center justify-content-center overflow-hidden position-relative" *ngIf="(loadingChatbot$ | async)!">
      <div class="dot-flashing position-relative"></div>
    </div>
    <form class="d-flex" [formGroup]="form" (ngSubmit)="onSubmit(form, steps)">
      <div class="flex-grow-1 me-2">
        <input type="text" placeholder="{{ 'ASK_ME_ANYTHING' | translate }}" class="w-100 px-3 py-2" formControlName="answer" />
      </div>
      <button mat-flat-button color="primary" type="submit" class="send-btn">
        <svg-icon name="submit" [applyClass]="true"></svg-icon>
      </button>
    </form>

    <div class="powered-by d-flex justify-content-end mt-3 align-items-center">
      <span>{{ 'POWERED_BY' | translate }}</span>
      <img src="assets/logo/logo-icon.png" alt="" draggable="false" width="17px" height="18px" class="mx-2" />
      <strong>{{ 'METUTORS_AI' | translate }}</strong>
    </div>
  </div>
</div>

<div class="position-fixed chatbot-icon" [@dropMenuAnimate] *ngLet="(openChatbot$ | async)! as isOpen"
     [class.is-open]="isOpen" [class.hide-chat]="hideChatbot">
  <button mat-flat-button color="primary" (click)="handleChatbot(isOpen)" class="d-flex align-items-center justify-content-center"
          [class.opened]="isOpen">
    <img [src]="isOpen ? 'assets/svg/chevron-down.svg' : 'assets/svg/chatbot.svg'" draggable="false" alt="">
  </button>

  <div class="chatbot-desc">
    <button mat-button class="close-chat d-flex align-items-center justify-content-center" (click)="hideChatbot = true">
      <mat-icon>close</mat-icon>
    </button>
    <h3 class="mb-0">
      <bdi [innerHTML]="'HELLO_DANIA_GITIAL_ASSISTANT' | translate"></bdi>
    </h3>
  </div>
</div>

<mat-menu #menu="matMenu">
  <a routerLink="/contact" (click)="closeChatbot()" mat-menu-item class="h-auto line-height-40">
    <span>{{ 'SEND_US_MESSAGE' | translate }}</span>
  </a>
  <button mat-menu-item class="h-auto line-height-40" (click)="closeChatbot()">
    <span>{{ 'CLOSE' | translate }}</span>
  </button>
</mat-menu>
